<script setup lang="ts">
import { computed, ref, useSlots, watch } from 'vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import { textForLocale } from '@/utils/helpers/locale'
import { useI18n } from 'vue-i18n'
import { VTextarea } from 'vuetify/lib/components/VTextarea/index.mjs'
import { VTextField } from 'vuetify/lib/components/VTextField/index.mjs'
import { useVuelidate } from '@vuelidate/core'
import { type AttributeValueRules } from './ComponentAttributeFloat.vue'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  attribute: Attribute
  validation?: Record<'attributeValue', AttributeValueRules>,
  hint?: string
  showSuffix?: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attributeValue': [ value: string ]
}>()

const slots = useSlots()

const { t } = useI18n()

const attributeName = computed(() => textForLocale(props.attribute.nameDE ?? '', props.attribute.nameEN ?? ''))

const suffixContent = computed(() => {
  if (!slots['append-inner'] && props.showSuffix) return props.attribute.default_unit ?? ''

  return undefined
})

const textComponentType = computed(() => {
  if (props.attribute.value_type === 'string') return VTextarea

  return VTextField
})

const attributeValue = ref<string|undefined>(props.attribute.value)

const v$ = useVuelidate(props.validation ?? {}, { attributeValue })

function handleUpdateAttributeValue () {
  if (attributeValue.value === undefined) return

  v$.value.$touch()
  v$.value.$validate()

  if (attributeValue.value === props.attribute.value || validationErrorMessages.value.length) return

  emits('update:attributeValue', attributeValue.value as string)
}

const validationErrorMessages = computed(() => {
  if (!v$.value.$errors.length && !props.attribute.error) return []

  return props.attribute.error ? [t('requestMessage')] : v$.value.$errors.map(e => e.$message)
})

watch(() => [props.attribute.value, props.attribute.error], () => {
  attributeValue.value = props.attribute.value
  v$.value.$reset()
})
</script>

<template>
  <v-row
    data-testid="component-attribute"
    class="attribute-row"
  >
    <v-col class="attribute-name">
      <span class="text-body-1 text-neutral-darken3">{{ attributeName }}</span>
    </v-col>
    <v-col
      v-if="slots['custom-input']"
      v-bind="$attrs"
      class="attribute-value"
    >
      <slot name="custom-input" />
    </v-col>
    <v-col
      v-else
      class="attribute-value"
    >
      <Component
        :is="textComponentType"
        v-model="attributeValue"
        :error-messages="validationErrorMessages"
        v-bind="$attrs"
        density="compact"
        variant="outlined"
        rows="1"
        max-rows="3"
        auto-grow
        :hint="props.hint"
        hide-details="auto"
        :suffix="suffixContent"
        :placeholder="props.attribute.default_value ?? ''"
        class="tw-w-full"
        color="neutral-darken2"
        @blur="handleUpdateAttributeValue"
        @update:model-value="v$?.value?.$touch()"
        @keydown.enter="handleUpdateAttributeValue"
      >
        <template
          v-for="(_, slot) of $slots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </Component>
    </v-col>
    <v-col
      v-if="props.attribute?.tooltip_text"
      class="attribute-icon"
    >
      <v-tooltip
        :text="props.attribute?.tooltip_text"
        location="end"
        content-class="aedifion-tooltip text-neutral-darken3 tw-max-w-[249px]"
      >
        <template #activator="{ props: tooltipProps }">
          <v-icon
            size="14"
            v-bind="tooltipProps"
            data-testid="attribute-info-icon"
            color="neutral-darken1"
          >
            fa:far fa-info-circle
          </v-icon>
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<style lang="sass" scoped>
  .attribute-row
    @apply tw-flex-nowrap tw-min-h-[40px] tw-h-fit tw-items-center tw-px-3 tw-mt-0 tw-my-2

  .attribute-name
    @apply tw-p-0 tw-mr-2 tw-max-w-[268px] tw-leading-5 tw-min-h-[40px] tw-h-fit tw-content-center tw-break-words tw-py-1

  .attribute-value
    @apply tw-p-0 tw-min-h-[40px] tw-content-center tw-max-w-[276px]
    :deep(.v-text-field__suffix)
      opacity: 1 !important
    :deep(.v-field__input)
      font-size: 14px
      line-height: 20px
      padding: 10px
      color: rgb(var(--v-theme-neutral-darken3)) !important
      opacity: 1 !important
    :deep(.fa-chevron-down)
      color: rgb(var(--v-theme-neutral-darken3)) !important

  .attribute-icon
    @apply tw-max-w-[24px]

</style>

<i18n lang="json" locale="de">
  {
    "requestMessage": "Anfrage fehlgeschlagen."
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "requestMessage": "Request failed."
  }
</i18n>
