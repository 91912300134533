<script setup lang="ts">
import { ref, watch } from 'vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import { useI18n } from 'vue-i18n'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

const { t } = useI18n()

const attributeValue = ref(getAttributeValue())

function getAttributeValue () {
  if (!props.attribute.value) {
    return props.attribute.default_value === 'True'
  }

  return props.attribute.value === 'True'
}

function toggleSwitch () {
  attributeValue.value = !attributeValue.value
  const newValue = attributeValue.value ? 'True' : 'False'

  emits('update:attribute', { ...props.attribute, new_value: newValue })
}

watch(() => props.attribute.value, () => {
  attributeValue.value = getAttributeValue()
})
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="attribute"
  >
    <template #custom-input>
      <v-text-field
        readonly
        hide-details
        variant="outlined"
        density="compact"
        color="neutral-darken2"
        class="tw-pointer-events-none"
      >
        <template
          #prepend-inner
        >
          <v-switch
            v-model="attributeValue"
            class="boolean-attribute-switch tw-pointer-events-auto tw-cursor-pointer"
            color="success"
            density="compact"
            width="26px"
            inset
            hide-details
            :label="`${attributeValue ? t('yes') : t('no')}`"
            @click.stop="toggleSwitch"
          />
        </template>
      </v-text-field>
    </template>
  </ComponentAttributeBaseRow>
</template>

<style scoped lang="sass">
:deep(.boolean-attribute-switch .v-switch__track)
  height: 16px
  width: 16px
  &.bg-success
    opacity: 1

:deep(.v-selection-control__wrapper)
  max-width: 26px !important
  &.text-success
    .v-switch__thumb
      position: relative
      transform: translateX(-6px)
  .v-switch__thumb
    height: 12px
    width: 12px
    transform: scale(1)
    transform: translateX(6px)

:deep(.v-switch--inset .v-switch__track)
  max-width: 26px
  height: 16px
  min-width: 26px

:deep(.boolean-attribute-switch .v-label)
  white-space: nowrap
  color: var(--v-theme-neutral-darken3)
  font-weight: 500
  padding-inline-start: 8px

:deep(.v-selection-control__input input)
  opacity: 0

:deep(.v-field--prepended)
  padding-inline-start: 10px
</style>

<i18n lang="json" locale="de">
  {
    "yes": "Ja",
    "no": "Nein"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "yes": "Yes",
    "no": "No"
  }
</i18n>
