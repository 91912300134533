<script setup lang="ts">
import { computed, type ComputedRef, onBeforeUnmount, onMounted, ref } from 'vue'
import SearchInput from '@/components/SearchInput.vue'
import type { SelectItem } from '@/utils/types'
import { useI18n } from 'vue-i18n'
import useInfiniteScroll from '@/composables/useInfiniteScroll'

const { t } = useI18n()

export type DropDownItem<T = string> = SelectItem<T> & {
  subtitle?: string
}

interface Props {
  color?: string;
  disabled?: boolean,
  menuIcon?: string;
  items: DropDownItem[];
  showSubtitle?: boolean;
  label?: string;
  loadMoreCallback?: (() => Promise<void>)|void,
  menuHint?: string;
  prependedItems?: DropDownItem[];
  search?: string;
  selectFirstItem?: boolean;
  selectionSuffix?: string;
  value?: string|null;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary-darken2',
  disabled: false,
  showSubtitle: true,
  selectFirstItem: true,
  id: 'dropDown',
})

const menuWidth = ref(0)

const selection = computed<string|null>(() => {
  if (props.value) {
    return props.value
  } else if (props.selectFirstItem && props.items.length > 0) {
    return props.items[0].value
  } else {
    return null
  }
})

// eslint-disable-next-line vue/no-setup-props-destructure
const prependedSelection = ref<string|null>(props.prependedItems?.[0]?.value ?? null)

const select = ref<HTMLElement|null>(null)

const highlightedClass: ComputedRef<(itemValue: string) => {
  unitClass: string;
  valueClass: string;
}> = computed(() => (itemValue: string) => {
  return {
    unitClass: itemValue === prependedSelection.value ? 'text-primary-darken2' : 'text-neutral-darken1',
    valueClass: itemValue === prependedSelection.value ? 'text-primary-darken2' : 'text-neutral-darken3',
  }
})

const menuInnerText = computed<string>(() => {
  const selectedPrependedItem = props.prependedItems?.find(item => item.value === prependedSelection.value)
  const selectedItem = props.items.find(item => item.value === selection.value)
  return `${selectedPrependedItem ? selectedPrependedItem.title + ' · ' : ''}${selectedItem?.title ?? ''}${props.selectionSuffix ? ' · ' + props.selectionSuffix : ''}`
})

function onResize (): void {
  if (select.value) {
    menuWidth.value = select.value.clientWidth
  }
}

function onChange (newValue: string|null): void {
  emit('update:value', newValue)
}

function onClick (): void {
  emit('dropdown:click', selection.value)
}

function onFocus (): void {
  emit('dropdown:focus')
}

onMounted (() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

const emit = defineEmits<{
  (e: 'dropdown:click', value: string|null): void,
  (e: 'dropdown:change', value: string|null): void,
  (e: 'dropdown:focus'): void,
  (e: 'update:search', value: string): void,
  (e: 'update:value', value: string|null): void
}>()

const infiniteScrollObserverTarget = ref<HTMLDivElement | null>(null)

if (props.loadMoreCallback) {
  useInfiniteScroll(
    infiniteScrollObserverTarget,
    props.loadMoreCallback,
    {
      rootMargin: '100px',
    },
  )
}

</script>

<template>
  <div
    :id="props.id"
    :class="{ 'aedifion-disabled': props.disabled }"
  >
    <v-select
      ref="select"
      :model-value="selection"
      class="drop-down"
      density="compact"
      hide-details
      :menu-icon="menuIcon"
      :items="props.items"
      variant="outlined"
      :color="props.color"
      :menu-props="{attach: `#${props.id}`, location: 'bottom start'}"
      :disabled="props.disabled"
      @click="onClick"
      @update:model-value="onChange"
      @focus="onFocus"
    >
      <template
        v-for="(_, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
      <template
        v-if="props.label"
        #prepend-inner
      >
        <span
          class="label align-self-center text-no-wrap text-body-1 pr-1"
          v-text="props.label"
        />
      </template>
      <template
        v-if="!$slots['selection']"
        #selection
      >
        <span
          data-testid="drop-down-selection"
          class="text-no-wrap text-cta text-neutral-darken3"
          v-text="menuInnerText"
        />
      </template>
      <template
        v-if="props.menuHint || props.loadMoreCallback"
        #append-item
      >
        <div
          v-if="props.loadMoreCallback"
          ref="infiniteScrollObserverTarget"
        />
        <template v-if="props.menuHint">
          <v-spacer style="height: 8px" />
          <v-divider />
          <v-list-item class="py-2">
            <template
              #prepend
            />
            <v-icon
              class="mr-2"
              start
              size="small"
            >
              fa:far fa-circle-info
            </v-icon>
            <div class="pb-0">
              <span
                class="text-body-1 text-neutral-darken3"
                v-text="props.menuHint"
              />
            </div>
          </v-list-item>
        </template>
      </template>
      <template #item="{ item, props: listItemProps }">
        <v-list-item
          v-bind="listItemProps"
          :to="item.raw?.custom?.to"
          class="drop-down-list-item"
        >
          <template #prepend>
            <slot
              :item="item"
              name="item-prepend"
            />
            <v-icon
              v-if="item.value === props.value && !$slots['item-prepend']"
              class="text-primary-darken2"
              start
              size="small"
            >
              fa:far fa-check
            </v-icon>
          </template>
          <template #title>
            <slot
              :item="item"
              name="item-title"
            />
            <span
              v-if="!$slots['item'] && !$slots['item-title']"
              :class="[highlightedClass(item.value).valueClass, 'text-cta item-text']"
              v-text="item.title"
            />
          </template>
          <template #append>
            <slot
              :item="item"
              name="item-append"
            />
          </template>
          <div class="ml-auto d-flex align-center">
            <div>
              <span
                v-if="item.raw.subtitle && props.showSubtitle"
                class="text-body-1 text-right subtitle"
                v-html="item.raw.subtitle"
              />
            </div>
            <v-list-item-action class="ml-2">
              <slot
                :item="item"
                name="item-action"
              />
            </v-list-item-action>
          </div>
        </v-list-item>
      </template>
      <template
        v-if="props.search !== undefined || props.prependedItems"
        #prepend-item
      >
        <SearchInput
          v-if="props.search !== undefined"
          class="search-input pt-2 px-2"
          :placeholder="t('search_placeholder')"
          outlined
          :model-value="props.search"
          @update:model-value="emit('update:search', $event)"
        />
        <template v-if="props.prependedItems">
          <v-list-item
            v-for="(item, index) in props.prependedItems"
            :key="index"
            class="drop-down-list-item"
            @click="prependedSelection = item.value"
          >
            <template #prepend>
              <v-icon
                v-if="item.value === prependedSelection"
                class="text-primary-darken2 mr-2"
                start
                size="small"
              >
                fa:far fa-check
              </v-icon>
            </template>
            <v-icon
              v-if="item.value === prependedSelection"
              class="text-primary-darken2"
              start
              size="small"
            >
              fa:far fa-check
            </v-icon>

            <span
              :class="[highlightedClass(item.value).valueClass, 'text-cta item-text']"
              v-text="item.title"
            />

            <span
              v-if="item.subtitle"
              :class="[highlightedClass(item.value).unitClass, 'ml-auto text-body-1 text-right subtitle']"
              v-html="item.subtitle"
            />
          </v-list-item>
          <v-divider class="my-2" />
        </template>
      </template>
    </v-select>
  </div>
</template>

<style lang="sass" scoped>
.search-input
  background-color: rgb(var(--v-theme-neutral-lighten5))
  position: sticky
  top: 0
  right: 0
  left: 0
  z-index: 1
  padding-bottom: 8px !important

.drop-down
  min-width: fit-content
  :deep(.v-select__slot input)
    cursor: pointer !important

  :deep(.v-input__prepend-inner, :deep(.v-input__append-inner))
    align-self: unset
    margin-top: 0 !important

  :deep(.v-icon)
    font-size: 14px
    color: rgb(var(--v-theme-neutral-darken3)) !important

  :deep(fieldset)
    border-width: 1px
    color: rgb(var(--v-theme-neutral-lighten1)) !important

  .label
    color: rgb(var(--v-theme-neutral-darken1))

  &.v-input--is-focused
    .label
      color: rgb(var(--v-theme-primary-darken2)) !important

    :deep(fieldset)
      border-width: 1px
      color: rgb(var(--v-theme-primary)) !important

  &:hover
    .label
      color: rgb(var(--v-theme-primary-darken2)) !important

    :deep(fieldset)
      color: rgb(var(--v-theme-primary-lighten2)) !important

    &.v-input--is-focused
      :deep(fieldset)
        color: rgb(var(--v-theme-primary)) !important

    :deep(.v-input__control)
      background-color: rgb(var(--v-theme-primary-lighten4)) !important

.drop-down-list-item
  color: rgb(var(--v-theme-neutral-darken3)) !important
  display: fixed !important

  .item-text
    white-space: nowrap

  &:not(.v-list-item--active) .subtitle
    color: rgb(var(--v-theme-neutral-darken1))

  &:before
    content: none

  &:hover
    background-color: rgb(var(--v-theme-primary-lighten4)) !important
    color: rgb(var(--v-theme-primary-darken2)) !important

    .item-text
      color: rgb(var(--v-theme-primary-darken2)) !important

    .subtitle
      color: rgb(var(--v-theme-primary-darken2)) !important

:deep(.v-select__slot .v-select__selections > input)
  display: none !important

// This has no effect, but is necessary to test the component, because the v-select was not scrollable in the test
:deep(.v-select__content)
  overflow-y: auto

#dropDown
  height: fit-content

:deep(.v-list)
  padding-top: 0 !important

:deep(.v-list-item)
  align-items: center
  display: flex
  flex: 1 1 100%
  letter-spacing: normal
  min-height: 48px
  outline: none
  padding: 0 16px
  position: relative
  text-decoration: none

:deep(.v-list-item__content)
  align-items: center !important
  align-self: center !important
  display: flex !important
  flex-wrap: wrap !important
  flex: 1 1 !important
  overflow: visible !important
:deep(.v-list-item__spacer)
  width: 0px !important
:deep(.v-input__details)
  margin-top: 15px
.text-body-1.text-right.subtitle
  text-align: right !important
</style>

<style lang="sass">
div.drop-down-menu
  height: fit-content !important
  left: unset !important
  top: unset !important
  right: unset !important
</style>

<i18n lang="json" locale="de">
  {
    "search_placeholder": "Suche"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "search_placeholder": "Search"
  }
</i18n>
