<script setup lang="ts">
import type { Attribute, VueComponentType } from '@/vuex/component_attributes_editor/types'
import vuexStore from '@/vuex'
import { CondensedAnalysisFunctionWithResult } from '@/vuex/optimization/getters'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import ComponentAttributeCertificate from './ComponentAttributeCertificate.vue'
import ComponentAttributeEnum from './ComponentAttributeEnum.vue'
import ComponentAttributeFloat from './ComponentAttributeFloat.vue'
import ComponentAttributeJSON from './ComponentAttributeJSON.vue'
import ComponentAttributeScheduler from './ComponentAttributeScheduler/ComponentAttributeScheduler.vue'
import ComponentAttributeString from './ComponentAttributeString.vue'
import ComponentAttributeLink from './ComponentAttributeLink.vue'
import ComponentAttributeBoolean from './ComponentAttributeBoolean.vue'
import ComponentHeader from './ComponentHeader.vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import SearchInput from '@/components/SearchInput.vue'
import { useI18n } from 'vue-i18n'
import AnalysisFilter from './AnalysisFilter.vue'
import { getFallbackName } from '@/utils/helpers/locale'
import { AnalysisFunction } from '@aedifion.io/aedifion-api'

// #region INITIALIZATIONS OF PROPS AND COMPOSABLES
interface Props {
  attributes: Attribute[],
  componentInProject: ComponentInProjectListItemData,
  loading: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'set-attribute-error': [ value: Attribute ],
  'update:attribute': [ value: Attribute]
}>()

const dialogState = defineModel()

const { t, locale } = useI18n()

function getVueComponentType (attributeType: VueComponentType) {
  switch (attributeType) {
    case 'float':
      return ComponentAttributeFloat
    case 'json':
      return ComponentAttributeJSON
    case 'certificate':
      return ComponentAttributeCertificate
    case 'schedule':
      return ComponentAttributeScheduler
    case 'link':
      return ComponentAttributeLink
    case 'boolean':
      return ComponentAttributeBoolean
    case 'enum':
      return ComponentAttributeEnum
    default:
      return ComponentAttributeString
  }
}
// #endregion

// #region COMPONENT ATTRIBUTES
const search = ref('')
const selectedAnalyticsFunction = ref('all')

const filteredAttributes = computed(() => {
  const attributeName = locale.value === 'de' ? 'nameDE' : 'nameEN'

  if (selectedAnalyticsFunction.value === 'all') {
    return props.attributes.filter((attribute) => {
      return attribute[attributeName]!.toLowerCase().includes(search.value.toLowerCase())
    })
  }

  // TODO: Fix any type after the sprint API package is ready
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedAnalyticsAttributes: AnalysisFunction[] = (vuexStore.state.analysis_functions.analysisFunctionsList!.find((functionItem) => functionItem.id?.toString() === selectedAnalyticsFunction.value)as any)?.attributes ?? []

  return props.attributes.filter((attribute) => selectedAnalyticsAttributes.some(selectedAnalyticsAttribute => selectedAnalyticsAttribute.id === attribute.id)).filter((attribute) => {
    return attribute[attributeName]!.toLowerCase().includes(search.value.toLowerCase())
  })
})

watch(dialogState, (newDialogState) => {
  if (newDialogState) {
    search.value = ''
  }
})
// #endregion

// #region ADD BORDER TO SCROLLABLE DIV
const scrollableDiv = ref<HTMLDivElement|null>(null)
const isDivScrolled = ref(false)

const handleScroll = () => {
  if (scrollableDiv.value) {
    isDivScrolled.value = scrollableDiv.value.scrollTop > 0
  }
}

watch(scrollableDiv, () => {
  if (scrollableDiv.value !== null && !props.loading) {
    scrollableDiv.value.addEventListener('scroll', handleScroll)
  }
}, { once: true })

onBeforeUnmount(() => {
  if (scrollableDiv.value) {
    scrollableDiv.value.removeEventListener('scroll', handleScroll)
  }
})
// #endregion

// #region ANALYTICS FUNCTIONS FILTER
const getAnalysisEnabled = (id: number | null) => {
  const analysisFunctionsWithResults: Array<CondensedAnalysisFunctionWithResult>|null = vuexStore.getters['optimization/getAnalysisFunctionsWithResults']

  if (analysisFunctionsWithResults === null) {
    return false
  } else {
    return analysisFunctionsWithResults.find((analysisFunction) => analysisFunction.function_id === id)?.enabled
  }
}

const analyticsFunctionsItems = computed(() => {
  const items = []
  const allAnalysisOption = {
    title: t('all-analysis'),
    value: 'all',
  }

  items.push(allAnalysisOption)
  items.push(...vuexStore.state.analysis_functions.analysisFunctionsList!.map((functionItem) => {
    return {
      title: getFallbackName(functionItem.nameDE, functionItem.nameEN),
      value: functionItem.id?.toString(),
      custom: getAnalysisEnabled(functionItem.id),
    }
  }))

  return items
})

function handleUpdateAnalyticsFunction (value: string) {
  selectedAnalyticsFunction.value = value
}
</script>

<template>
  <v-dialog
    v-model="dialogState"
    data-testid="component-attributes-editor"
    width="640"
    max-height="calc(100vh - 80px)"
    content-class="aedifion-box-shadow-no-border tw-absolute tw-top-4"
  >
    <v-card
      class="tw-p-0 tw-border-0"
    >
      <ComponentHeader
        :title="componentInProject.title"
        :description="componentInProject.description"
      >
        <template #default>
          <v-btn
            class="tw-ml-auto"
            icon
            data-testid="dialog-close-button"
            size="14"
          >
            <v-icon
              size="14"
              @click="dialogState = false"
            >
              fa:far fa-xmark-large
            </v-icon>
          </v-btn>
        </template>
      </ComponentHeader>
      <div class="tw-p-6 tw-pb-0 bg-neutral-lighten3 tw-font-semibold">
        <h5 class="tw-mb-6 text-h5">
          {{ t('componentAttributes') }}
        </h5>
        <div class="tw-relative tw-mb-6">
          <SearchInput
            v-model="search"
            :color="'neutral-darken2'"
            :placeholder="t('search')"
            :show-icon="false"
          />
          <AnalysisFilter
            v-if="vuexStore.state.analysis_functions.analysisFunctionsList !== null"
            :analytics-functions-items="analyticsFunctionsItems"
            class="tw-absolute tw-right-0 tw-top-0 analysis-dropdown"
            @update:analytics-function="handleUpdateAnalyticsFunction"
          />
        </div>
      </div>
      <div
        v-if="!props.loading"
        ref="scrollableDiv"
        :class="['tw-h-full tw-overflow-y-auto tw-overflow-x-hidden tw-pt-0 tw-pb-5 tw-px-6 tw-border-t tw-border-transparent bg-neutral-lighten3', { 'scrollable-div': isDivScrolled }]"
      >
        <template
          v-for="attribute in filteredAttributes"
          :key="attribute.id"
        >
          <Component
            :is="getVueComponentType(attribute.vue_component_type)"
            :attribute="attribute"
            :component-in-project="componentInProject"
            @update:attribute="(attribute) => emits('update:attribute', attribute)"
            @set-attribute-error="(attribute) => emits('set-attribute-error', attribute)"
          />
        </template>
      </div>
      <div
        v-else
        class="tw-px-6 bg-neutral-lighten3 tw-h-full tw-overflow-y-auto"
        data-testid="skeleton-loader"
      >
        <div
          v-for="i in 25"
          :key="i"
          class="tw-flex"
        >
          <v-skeleton-loader
            class="tw-mt-2 tw-mr-2 tw-w-[50%] bg-neutral-lighten3"
            :height="40"
            type="text"
            :width="592"
          />
          <v-skeleton-loader
            class="tw-mt-2 tw-w-[50%]"
            :height="40"
            :width="592"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
.scrollable-div
  transition: border-top 0.3s ease
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important

.title-border
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))

:deep(.analysis-dropdown .v-input__control .v-field)
  border-top-left-radius: 0
  border-bottom-left-radius: 0

:deep(#analysis-dropdown .v-overlay-container .v-list .v-list-item)
  &:nth-child(2)
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))

:deep(.v-list)
  min-width: 290px
</style>

<i18n locale="de" lang="json">
  {
    "all-analysis": "Alle Analysen",
    "componentAttributes": "Komponentenattribute",
    "search": "Suche"
  }
</i18n>
<i18n locale="en" lang="json">
  {
    "all-analysis": "All analyses",
    "componentAttributes": "Component Attributes",
    "search": "Search"
  }
</i18n>
