{
  "locale_names": {
    "de": "Deutsch",
    "en": "Englisch"
  },
  "asset_overview": {
    "extrapolated_data": "Hochrechnung der neuesten Verbrauchsdaten"
  },
  "actions": {
    "cancel": "Abbrechen",
    "ok": "Ok",
    "save": "Speichern",
    "close": "Schließen",
    "clear": "Löschen",
    "back": "Zurück",
    "confirm": "Akzeptieren",
    "activated": "aktiviert",
    "deactivated": "deaktiviert",
    "triggered": "ausgelöst",
    "delete": "Löschen",
    "remove": "Entfernen",
    "edit": "Bearbeiten",
    "more": "Mehr sehen",
    "switch_on": "Einschalten",
    "switch_off": "Ausschalten"
  },
  "dates": {
    "chart": {
      "date_format": "%d.%m.%Y, %H:%M:%S",
      "weekday_date_format": "%a, %d.%m"
    },
    "date_format": "DD.MM.YYYY",
    "datetime_format": "DD.MM.YYYY HH:mm:ss",
    "short_weekdays": {
      "monday": "Mo",
      "tuesday": "Di",
      "wednesday": "Mi",
      "thursday": "Do",
      "friday": "Fr",
      "saturday": "Sa",
      "sunday": "So"
    },
    "time_period": "Zeitraum",
    "monthly": "Monatlich",
    "yearly": "Jährlich"
  },
  "errors": {
    "email_already_exists": "Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse.",
    "is_invalid": "{field} ist ungültig.",
    "is_required": "{field} ist ein Pflichtfeld.",
    "is_negative": "{field} darf nicht negativ sein.",
    "is_not_a_number": "{field} muss eine Nummer sein.",
    "does_not_respect_password_rules": "{field} muss aus mindestens 12 und höchstens 128 Zeichen bestehen, darunter mindestens eine Zahl, ein Groß- und Kleinbuchstabe und ein Sonderzeichen."
  },
  "form_fields": {
    "address": "Adresse",
    "assignee": "Zugewiesene Pers.",
    "description": "Beschreibung",
    "email": "E-Mail",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "name": "Name",
    "password": "Passwort",
    "phone": "Telefon",
    "language": "Sprache",
    "unit_systems": "Einheitensystem",
    "currency_systems": "Währung"
  },
  "highcharts": {
    "binary_values": "Binärwerte",
    "binary_no_data": "Alle Binärwerte sind 0",
    "loading": "Lade Daten",
    "no_data": "Keine Daten zur Visualisierung verfügbar",
    "reset_zoom": "Zoom zurücksetzen",
    "values": "Werte"
  },
  "links": {
    "meta": {
      "title": {
        "forwarding": "Weiterleitung",
        "forbidden": "Fehlende Berechtigungen",
        "auth_failed": "Authentifizierung fehlgeschlagen",
        "page_not_found": "Seite nicht gefunden",
        "user": {
          "profile": "Profil",
          "security": "Sicherheit"
        },
        "home": "Übersicht",
        "optimization": "Optimierung",
        "optimization_checklist": "Checkliste",
        "optimization_components": "Komponenten",
        "optimization_potential": "Einsparpotential",
        "optimization_savings": "Einsparungen",
        "data_points_view": "Datenpunkte",
        "status_and_alerts": "Status & Alarme",
        "asset_overview": "Asset Übersicht",
        "asset_profile": "Profil",
        "ai_controls": "KI Regelung",
        "ai_controls_app": "App",
        "ai_controls_log": "Log",
        "administration": "Administration",
        "manage_users": "Benutzer verwalten",
        "data_privacy": "Datenschutz",
        "cookie_list": "Cookies",
        "energy_and_co2": "Energie & CO₂",
        "reporting": "Reporting",
        "meter": "Zähler"
      }
    }
  },
  "topbar": {
    "links": {
      "my_account": "My Profile",
      "my_company": "My Company",
      "security": "Sicherheit",
      "settings": "Settings"
    }
  },
  "pagination": {
    "page": "Seite",
    "of": "von"
  },
  "notifications": {
    "errors": {
      "fetch": "{resource} aktuell nicht verfügbar. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
      "no_project_selected": "Es muss ein Projekt ausgewählt sein, um diese Aktion durchzuführen. Aktion wird unterbrochen.",
      "no_project_selected_with_attempted_action": "Es muss ein Projekt ausgewählt sein, {action}. Aktion wird unterbrochen.",
      "no_component_in_project": "Komponente konnte im Projekt nicht gefunden werden.",
      "projects": {
        "loadProjects": "Die Projekte konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "create": "{resource} konnte nicht erstellt werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
      "delete": "{resource} konnte nicht gelöscht werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support</b>.",
      "update": "{resource} konnte nicht aktualisiert werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support</b>.",
      "clipboard": "Konnte \"{string}\" nicht in die Zwischenablage kopieren.",
      "user": {
        "assignRoles": "Dem Benutzer konnten eine oder mehrere Rollen nicht zugewiesen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "emailNotUnique": "Benutzer konnte nicht erstellt werden: Ein anderer Benutzer mit dieser E-Mail-Adresse existiert bereits.",
        "updateAvatar": "Der Avatar konnte nicht hochgeladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "deleteAvatar": "Der Avatar konnte nicht entfernt werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "fetch": "Deine Daten konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "updateDetails": "Deine Daten konnten nicht aktualisiert werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "currentPasswordIncorrect": "Das aktuelle Passwort ist nicht korrekt.",
        "endpointLocked": "Du hast zu häufig versucht, dein Passwort zu ändern. Bitte versuche es später erneut.",
        "updatePassword": "Das Passwort konnte nicht geändert werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "endpoints": {
        "fetch": "Verbindungsdaten konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "datapoints": {
        "fetch": "Die Datenpunkte konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "favorite": "Favoritenstatus des Datenpunkts konnte nicht geändert werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "data_points_view": {
        "fetchTimeseries": {
          "error": "Zeitreihendaten konnten nicht geladen werden.",
          "missing_arguments": "Zeitreihendaten konnten wegen fehlenden informationen nicht geladen werden."
        }
      },
      "tags": {
        "fetch": "Die Tags konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "missing_arguments": "Tags konnten aufgrund von fehlenden Informationen nicht geladen werden.",
        "remove": "Der Tag konnte nicht gelöscht werden. Bitte stelle sicher, dass dieses Tag gelöscht werden kann, oder versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "add": "Der Tag konnte nicht hinzugefügt werden. Bitte versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "update": "Tag-Wert konnte nicht aktualisiert werden. Bitte versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "duplicated": "Das Erstellen eines Tags ist fehlgeschlagen, da bereits ein anderes Tag mit demselben Schlüssel zugewiesen ist. Bitte entferne es zuerst oder kontaktiere den Support."
      },
      "descriptions": {
        "remove": "Die Beschreibung konnte nicht entfern werden. Bitte stelle sicher, dass sie entfernt werden kann, oder versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "add": "Die Beschreibung konnte nicht erstellt werden. Bitte versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "update": "Die Beschreibung konnte nicht aktualisiert werden. Bitte versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "duplicated": "Das Erstellen der Beschreibung ist fehlgeschlagen, da bereits eine Beschreibung existiert. Bitte entferne diese zuerst oder kontaktiere den Support."
      },
      "components": {
        "fetch": "Die Komponenten konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "create": "Die Komponente konnte nicht erstellt werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "users": {
        "fetch": "Die Benutzer konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "componentInProject": {
        "delete_component_in_project": "Die Komponente konnte nicht gelöscht werden. Bitte versuche es in wenigen Minuten erneut. Wenn der Fehler weiterhin besteht kontaktiere bitte den Support.",
        "duplicated": "Eine Komponente mit diesem Namen existiert bereits für dieses Projekt. Bitte verwende einen anderen Namen.",
        "fetch": "Die Komponente konnte nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "update": "Die Komponente konnte nicht aktualisiert werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "analysis_instances": {
        "fetch": "Analyse-Instanzen konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "functions": {
        "fetch": "Analysefunktionen konnten nicht geladen werden. Bitte versuche es in wenigen Minuten erneut. Sollte das Problem bestehen bleiben kontaktiere bitte den Support.",
        "toggle_instance": "Die Analysefunktion konnte nicht {action} werden. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "assetOverview": {
        "fetch": "Die Gebäudedaten konnten nicht geladen werden. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "mapping": {
        "mapped": "Der gewählte Datenpunkt ist der Komponente bereits zugeordnet. Bitte korrigiere die Zuordnung oder wähle einen anderen."
      },
      "optimization": {
        "toggle": "Die Optimierung konnte nicht {action} werden. Sollte das Problem bestehen bleiben kontaktiere bitte den Support."
      },
      "download": "Download für {resource} fehlgeschlagen. Bitte versuche es erneut."
    },
    "info": {
      "analysis_running": "Die Berechnung der KPIs für die hinzugefügten Zählerwerte hat begonnen."
    },
    "success": {
      "user": {
        "updatePassword": "Passwort erfolgreich geändert.",
        "updateDetails": "Profildaten erfolgreich geändert."
      },
      "tags": {
        "add": "Neuer Tag wurde erstellt.",
        "remove": "Tag wurde erfolgreich entfernt.",
        "update": "Tag wurde aktualisiert."
      },
      "descriptions": {
        "add": "Beschreibung erfolgreich hinzugefügt.",
        "remove": "Beschreibung erfolgreich entfernt.",
        "update": "Beschreibung erfolgreich aktualisiert."
      },
      "components": {
        "create": "Komponente erfolgreich erstellt."
      },
      "componentInProject": {
        "delete": "Komponente erfolgreich entfernt.",
        "update": "Komponente erfolgreich aktualisiert."
      },
      "functions": {
        "toggle_instance": "Die Analysefunktion {analysisFunction} wurde {action}."
      },
      "setpoint": {
        "write": "Sollwert erfolgreich angefragt",
        "release": "Sollwert erfolgreich freigegeben"
      },
      "create": "{resource} erfolgreich erstellt.",
      "delete": "{resource} erfolgreich entfernt.",
      "update": "{resource} erfolgreich aktualisiert.",
      "clipboard": "\"{string}\" in die Zwischenablage kopiert.",
      "analysis_completed": "Die Analyse wurde erfolgreich beendet."
    },
    "resources": {
      "alerts": "Alarme",
      "attribute_definition": "Definition der Attribute",
      "digital_twin": "Digitaler Zwilling des Projekts",
      "component_data": "Komponentendaten",
      "component": "Komponente",
      "comment": "Kommentar",
      "comments": "Kommentare",
      "analysis_result": "Analyseergebnis",
      "analysis_results": "Analyseergebnisse",
      "faults_data": "Systemdaten",
      "incident_alerts": "Ereignisse",
      "datapoint": "Datenpunkt",
      "company": "Firma",
      "avatar": "Avatar",
      "company_profile": "Firmenprofil",
      "label_definitions": "Labels",
      "label_systems": "Einheitensysteme",
      "user": "Benutzer",
      "files": "Dateien",
      "assets": "Assets",
      "reading": "Zählerstand",
      "savings_potential": "Einsparpotentiale",
      "meters": "Zähler",
      "controls_apps_algorithms": "Kontrollalgorithmen",
      "controls_app": "Controls App",
      "controls_apps": "Controls Apps",
      "controls_app_logs": "Controls App Logs",
      "tasks": "Aufgaben",
      "task": "Aufgabe",
      "checklist_report": "Checkliste-Report"
    }
  },
  "administration": {
    "administrator": "Admin"
  },
  "data_points_view": {
    "quick_filters": {
      "favorites": "Favoriten",
      "writable": "Schreibbar"
    },
    "tags": {
      "writable": "Schreibbar",
      "yes": "Ja"
    },
    "select_datapoint": "Wähle einen Datenpunkt aus."
  },
  "analysis_titles": {
    "technical_availability_analysis": "Technische Zuverlässigkeit",
    "co2_emission_analysis": "CO₂-Emissionen",
    "energy_cost_analysis": "Energiekosten",
    "occupancy_rate_analysis": "Flächennutzung",
    "wellbeing_analysis": "Wellbeing",
    "fallback_title": "Gebäude-KPI",
    "incidents": "Ereignisse",
    "energy_consumption_analysis": "Energieverbrauch"
  },
  "unit_systems": {
    "si": "Internationales Einheitensystem (SI)",
    "usc": "US-Amerikanisches Maßsystem (USC)",
    "metric": "Metrisch",
    "imperial": "Imperiales System"
  },
  "status_and_alerts": {
    "messages": {
      "ready_for_operation": "Betriebsbereit",
      "in_operation": "In Betrieb",
      "equipment_error_action_required": "Gravierender Fehler",
      "equipment_error_less_important": "Fehler",
      "non_critical_notification": "Meldung",
      "equipment_switched_off": "Ausgeschaltet"
    }
  },
  "messages": {
    "no_data": "Keine Daten"
  },
  "attributes": {
    "B++ADDRESS": "Standort des Gebäudes",
    "B++DESCRIPTION": "Beschreibung",
    "B++NAME": "Name des Gebäudes",
    "B_COUNT_CAR_MAX": "Anzahl der Parkplätze",
    "B_COUNT_PEO_MAX": "Belegungskapazität",
    "B_GFA_AV": "Mittlere Bruttogeschossfläche",
    "B+AHU": "Raumlufttechnische Anlagen",
    "B+ASSET_MANAGER": "Asset Manager",
    "B+ASSET_OWNER": "Assetbesitzer",
    "B+BA_BRAND": "Gebäudeautomationshersteller",
    "B+BA_CNSTR": "Gebäudeautomationserrichter",
    "B+BA_PROT": "Gebäudeautomationsprotokoll",
    "B+BMS_BRAND": "Gebäudeleittechnikhersteller",
    "B+BUILD_YEAR": "Baujahr",
    "B+CERTS_BREEAM": "BREEAM",
    "B+CERTS_DGNB": "DGNB",
    "B+CERTS_ECORE": "Ecore",
    "B+CERTS_ESTAR": "EnergyStar",
    "B+CERTS_GRESB": "GRESB",
    "B+CERTS_LEED": "LEED",
    "B+CERTS_WSCORE": "WiredScore",
    "B+CS": "Kühlungssystem",
    "B+DP_COUNT": "Anzahl der Datenpunkte",
    "B+FACILITY_MANAGER": "Facility Manager",
    "B+HS": "Heizungssystem",
    "B+ICT": "IKT Kontaktperson",
    "B+M": "Zähler",
    "B+NFA": "Nettogrundfläche",
    "B+PROP_MANA": "Property Management",
    "B+RENOV_YEAR": "Jahr der letzten Sanierung",
    "B+TYP": "Typ",
    "update_error": "Wert konnte nicht gespeichert werden, bitte versuche es noch einmal."
  },
  "building_type": {
    "office": "Büro",
    "retail": "Handel"
  },
  "certification_grades": {
    "platinum": "Platin",
    "gold": "Gold",
    "silver": "Silber",
    "bronze": "Bronze",
    "certified": "Zertifiziert"
  },
  "support_call_to_action": {
    "support": "Support"
  },
  "optimizations": {
    "high_potential": "Hohes Potenzial",
    "high_potentials": "Hohe Potenziale",
    "medium_potential": "Mittleres Potenzial",
    "medium_potentials": "Mittlere Potenziale"
  },
  "ai_controls": {
    "algorithm": {
      "ara": {
        "name": "Adaptive Room Temperature Algorithm",
        "description": "<p>Ändert die Raumtemperatur-Sollwerte, um einen vorausschauenden, bedarfsgerechten Betrieb für einen optimalen thermischen Komfort zu erreichen.</p>\n<p>Räume sollten während der Belegungszeiten angenehm temperiert sein, um den Nutzerkomfort zu maximieren, außerhalb dieser jedoch energiesparend geschaltet. Mit der Einstellung eines Belegungszeitplans startet die Raumklimatisierung allerdings erst zu Beginn der Belegung, was zu einer unkomfortablen ersten Stunde führt.</p>\n<p>Der ARA Algorithmus erweitert die Sollwertpläne dynamisch, so dass die Komfortbedingungen bei Ankunft des Nutzers erfüllt sind und die gesamte Vorkonditionierungsphase auf ein Minimum reduziert wird.</p>"
      },
      "bee": {
        "name": "Blind Controls Algorithm"
      },
      "custom": {
        "name": "Custom"
      },
      "dodo": {
        "name": "Decentralized occupancy driven optimisation"
      },
      "frog": {
        "name": "Free-Cooling Algorithm",
        "description": "<p>Implementiert eine freie Kühlung über eine Lüftungsanlage, die den Komfort der Nutzer priorisiert und unangenehm kalte/warme Büros am Morgen vermeidet.</p>\n<p>In Gebäuden werden die Kühlsysteme häufig tagsüber eingesetzt, um eine angenehme Temperatur aufrechtzuerhalten, während die kühle Luft in der Nacht genutzt werden kann, um die Gebäudetemperatur zu senken.</p>\n<p>Die freie Kühlung wird selektiv aktiviert, wenn der Betrieb von Lüftern zur Nachtkühlung kosteneffizienter ist als der Einsatz von Kompressionskältemaschinen während des Tages für eine ähnliche Kühlung. Diese Logik optimiert den Komfort und maximiert die Energieeinsparungen und bietet eine praktische, umweltbewusste Lösung für den Kühlbedarf von Gebäuden. Der FROG Algorithmus ist ideal für Gebäude, die im Sommer zu warm sind.</p>"
      },
      "heating_curve": {
        "name": "Heating Curve",
        "description": "<p>Passt die Vorlauftemperatur-Sollwerte in Echtzeit dynamisch an die Außentemperatur an, um den Energieverbrauch zu optimieren und den Raumkomfort zu erhalten.</p>\n<p>Der Heiz- und Kühlbedarf schwankt mit den Außenbedingungen, während statische Sollwerte sowohl zu Energieineffizienzen als auch zu einem suboptimalen Komfortniveau führen.</p>\n<p>Der Heizkurve Algorithmus stellt die Innentemperaturen auf der Grundlage der Außenbedingungen ein und sorgt so für eine nahtlose Anpassung an Wetteränderungen. Durch nächtliche Absenkungen können weitere Energieeinsparungen erzielt werden, indem die Vorlauftemperaturen gesenkt werden, wenn die Zeitpläne inaktiv sind. Die vorausschauende Steuerung passt die Temperaturen auf der Grundlage von Wettervorhersagen prädiktiv an, was insbesondere Systemen mit hoher thermischer Trägheit wie Fußbodenheizungen zugute kommt, die Effizienz optimiert und ein Überschreiten der Sollwerte verhindert.</p>"
      },
      "hero": {
        "name": "Heat Recovery Optimization",
        "description": "<p>Ermittelt die effizienteste Temperatur für ein Lüftungsgerät, das zum Heizen oder Kühlen eines Gebäudes verwendet werden kann.</p>\n<p>Viele RLT-Anlagen haben feste Zuluft- oder Ablufttemperatursollwerte, was zu geringer Wärmerückgewinnung oder Wärmeverlusten führt, wenn das Lüftungsgerät kühlt, während Heizkörper die Räume erwärmen.</p>\n<p>Die Zulufttemperatur wird indirekt zur Regelung der Ablufttemperatur verwendet: Ist die Ablufttemperatur zu niedrig, wird die Zulufttemperatur erhöht, ebenso in umgekehrter Weise. Dies geschieht solange, bis die Wärmerückgewinnung optimal ausgenutzt ist und möglichst nicht aktiv geheizt oder gekühlt werden muss.</p>"
      },
      "mpc": {
        "name": "Supervisory model predictive control"
      },
      "orc": {
        "name": "Optimized Room Climate Controller"
      },
      "prawn": {
        "name": "Predictive heating curve weather compensation"
      },
      "schedule": {
        "name": "Schedules",
        "description": "<p>Schaltet eine Anlage nach einem vordefinierten Zeitplan ein/aus, um in Zeiten, in denen sie nicht benötigt wird, Energie zu sparen.</p>\n<p>Viele HLK-Anlagen laufen oft im Dauerbetrieb oder nach ineffizienten Zeitplänen, obwohl sie nur für begrenzte Zeiträume benötigt werden.</p>\n<p>Der Zeitplan Algorithmus steuert, wann die Anlage zu bestimmten Zeiten startet und stoppt. Standardmäßig werden einfache Ein/Aus-Befehle (0 und 1) verwendet. Für Systeme, die andere Einstellungen erfordern, z. B. solche mit mehreren Betriebszuständen, können die Werte für das Starten und Stoppen jedoch nach Bedarf angepasst werden.</p>"
      },
      "wasp": {
        "name": "Weather-predictive Temperation-Mode Setter",
        "description": "<p>Optimiert Heiz-/Kühlsysteme mit hoher Trägheit anhand von Wettervorhersagen, indem Schwellenwerte auf Grundlage von 48-Stunden-Vorhersagen angepasst werden und verhindert so schnelle Heiz-/Kühlzyklen für eine bessere Effizienz.</p>\n<p>Systeme mit hoher thermischer Trägheit, die zum Heizen und Kühlen ausgelegt sind, können paradoxerweise häufig zwischen diesen beiden Funktionen wechseln. Ein thermisch aktivierter Betonkern kann beispielsweise heizen, wenn die Temperatur unter 18 °C sinkt, und kühlen, wenn sie über 21 °C steigt, was dazu führt, dass die Zyklen an fast 100 Tagen im Jahr stattfinden, wodurch das System ineffizient arbeitet.</p>\n<p>Der WASP Algorithmus verwendet 48-stündige Wettervorhersagen während der Arbeitszeit (8–18 Uhr), um zwischen Heizen und Kühlen zu entscheiden. Diese Entscheidung wird 24 Stunden lang beibehalten, bevor sie neu bewertet wird. Außerdem wird standardmäßig eine Nachtabsenkung durchgeführt, um die Aktivität außerhalb der Arbeitszeiten zu begrenzen, außer bei extremen Bedingungen.</p>"
      }
    }
  },
  "checklist": {
    "status": {
      "open": "Offen",
      "doing": "In Bearbeitung",
      "review": "Zur Überprüfung",
      "done": "Erledigt",
      "rejected": "Abgelehnt",
      "failed": "Fehlgeschlagen"
    },
    "priority": {
      "high": "Hohe Priorität",
      "medium": "Mittlere Priorität",
      "low": "Niedrige Priorität"
    },
    "headers": {
      "task_text": "Aufgabe",
      "task_tooltip": "Beschreibung der Aufgabe",
      "component_text": "Komponente",
      "costs_text": "Jährl. Einsparpot.",
      "costs_tooltip": "Kosteneinsparpotenzial (jährlich)",
      "created": "Startdatum",
      "status_text": "Status",
      "status_tooltip": "Status der Aufgabe"
    },
    "download_report_info": "Ihr Bericht wird erstellt. Der Download beginnt in Kürze ..."
  },
  "abbreviations": {
    "yearly": "a"
  },
  "incidents": {
    "info": "Es werden alle eingerichteten {alarms} dargestellt. Die grüne Signalfarbe wird dargestellt, wenn keine Meldungen vorhanden sind. Gelb wird dargestellt, wenn Alarme bereits bearbeitet wurden. Rot deutet auf offene Alarme hin. Bitte {contact_us} für die Einrichtung neuer Alarme.",
    "links": {
      "alarms": "Alarme",
      "contact_us": "kontaktiere uns"
    }
  },
  "samplerate_auto": "Automatisch",
  "samplerate_1h": "1 Stunde",
  "samplerate_15m": "15 Minuten",
  "field_requirements": {
    "password": {
      "casing": "einem Klein- und einem Großbuchstaben",
      "digit": "einer Zahl",
      "intro": "Das {field} muss bestehen aus:",
      "number_of_characters": "zwischen 12 und 128 Zeichen",
      "special": "einem Sonderzeichen"
    }
  },
  "meter_view": {
    "add_meter_data": "Zählerdaten hinzufügen",
    "delete_meter_confirmation_text": "Soll der Zähler wirklich gelöscht werden?",
    "delete_meter_confirmation_title": "Zähler löschen",
    "enter_meter_reading": "Bitte gib die Zählerstände ein, so dass wir den Verbrauch berechnen können.",
    "total": "Gesamt",
    "tooltip_text": "Um eine Vergleichbarkeit zwischen den Projekten zu gewährleisten, werden hier nur die Hauptzähler zusammengefasst. Die Liste kann auch Unterzähler enthalten, die separat gekennzeichnet sind.",
    "number_of_meters": "{count} Zähler",
    "submeter": "Unterzähler"
  },
  "savings_potential": {
    "details": "Einzelheiten",
    "filter_by_component": "Analysen nach Komponenten filtern"
  },
  "kpis": {
    "energy_cost": "Energiekosten",
    "energy_consumption": "Energieverbrauch",
    "co2_emissions": "CO₂-Emissionen",
    "productivity": "Wohlbefinden",
    "technical_availability": "Technische Verfügbarkeit"
  },
  "recently": "einigen Minuten",
  "for": "seit",
  "date_ranges": {
    "MMM D": "Do MMM",
    "YYYY MMM D": "Do MMM YYYY",
    "D": "Do"
  },
  "validations": {
    "allowed_values":"Werte von {min} bis {max} erlaubt.",
    "min": "Der Mindestwert ist {min}.",
    "max": "Der Höchstwert ist {max}."
  }
}
