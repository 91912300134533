<script lang="ts">
import { createI18nMessage, helpers } from '@vuelidate/validators'
import i18n from '@/i18n'
import { ValidationRuleWithParams } from '@vuelidate/core'

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

export type AttributeValueRules = {
  min?: ReturnType<typeof withI18nMessage>,
  max?: ReturnType<typeof withI18nMessage>,
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { ActionMenuItem } from '@/components/ActionMenu.vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import UnitSelector from './UnitSelector.vue'
import { useI18n } from 'vue-i18n'
import vuexStore from '@/vuex'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'set-attribute-error': [ value: Attribute ],
  'update:attribute': [ value: Attribute ]
}>()

const { locale, t } = useI18n()

const attributeUnit = computed(() => {
  const unit = vuexStore.getters['labels/label']('units', props.attribute?.unit ?? props.attribute?.default_unit)

  if (unit.display_name === 'units') {
    return null
  }

  return unit?.symbol || wrapDisplayName(unit?.display_name)
})

const sisterUnits = computed(() => {
  if (!props.attribute?.unit_type) return []

  return vuexStore.getters['labels/label']('units', props.attribute?.unit_type)
})

const unitList = computed<ActionMenuItem[]>(() => {
  return sisterUnits.value?.children?.map((unit: { id: number; symbol: string, display_name: string }) => ({
    id: unit.id,
    label: unit.symbol || wrapDisplayName(unit?.display_name),
  })) ?? []
})

const handleUpdateUnit = (unit: string) => {
  const unitIdentifier = unitList.value.find((unitItem) => unitItem.label === unit)?.id

  emits('update:attribute', {
    ...props.attribute,
    new_unit: unitIdentifier,
    new_value: props.attribute.value ?? '',
  })
}

const formattedAttribute = computed(() => {
  const formattedValue = props.attribute.value
    ? Intl.NumberFormat(locale.value, {
      maximumFractionDigits: 6,
      minimumFractionDigits: 1,
      notation: 'standard',
    }).format(parseFloat(props.attribute.value))
    : undefined

  return {
    ...props.attribute,
    value: formattedValue,
  }
})

function handleUpdateAttributeValue (newAttributeValue: string) {
  // This means the user has deleted the value
  if (newAttributeValue === '') {
    emits('update:attribute', {
      ...props.attribute,
      new_value: newAttributeValue,
    })
    return
  }

  const unformattedValue = undoFormattingBasedOnLocale(newAttributeValue)

  if (isNaN(unformattedValue)) {
    emits('set-attribute-error', props.attribute)
    return
  }

  emits('update:attribute', {
    ...props.attribute,
    new_value: unformattedValue.toString(),
  })
}

function undoFormattingBasedOnLocale (value: string) {
  if (locale.value === 'de') {
    return parseFloat(value.replace(/\./g, '').replace(/,/, '.'))
  }

  return parseFloat(value.replace(/,/g, ''))
}

function wrapDisplayName (displayName: string|undefined) {
  if (!displayName) return ''

  return `[${displayName}]`
}

// #region VALIDATION
function smallerThanFormattedValue (min: number) {
  return helpers.withParams({ min }, (value: string) => {
    if (undoFormattingBasedOnLocale(value) < min) {
      return false
    }

    return true
  })
}

function greaterThanFormattedValue (max: number) {
  return helpers.withParams({ max }, (value: string) => {
    if (undoFormattingBasedOnLocale(value) > max) {
      return false
    }

    return true
  })
}

function minValueValidator (min: number) {
  return helpers.withMessage(t('validations.min', { min }), smallerThanFormattedValue(min))
}

function maxValueValidator (max: number) {
  return helpers.withMessage(t('validations.max', { max }), greaterThanFormattedValue(max))
}

const attributeRules = computed<Record<'attributeValue', AttributeValueRules>>(() => {
  const rules = {} as Record<'min'|'max', ValidationRuleWithParams<object, unknown>>

  if (props.attribute.limits?.min !== undefined) {
    rules.min = minValueValidator(props.attribute.limits.min)
  }

  if (props.attribute.limits?.max !== undefined) {
    rules.max = maxValueValidator(props.attribute.limits.max)
  }

  return {
    attributeValue: rules,
  }
})

const allowedValuesHint = computed<string|undefined>(() => {
  if (props.attribute.limits?.min !== undefined && props.attribute.limits?.max !== undefined) {
    return t('validations.allowed_values', {
      min: props.attribute.limits.min,
      max: props.attribute.limits.max,
    })
  }

  if (props.attribute.limits?.min !== undefined) {
    return t('validations.min', { min: props.attribute.limits.min })
  }

  if (props.attribute.limits?.max !== undefined) {
    return t('validations.max', { max: props.attribute.limits.max })
  }

  return undefined
})
// #endregion
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="formattedAttribute"
    :validation="attributeRules"
    :hint="allowedValuesHint"
    show-suffix
    @update:attribute-value="handleUpdateAttributeValue"
  >
    <template
      v-if="unitList.length && attributeUnit"
      #append-inner
    >
      <UnitSelector
        :default-unit="attributeUnit"
        :unit-list="unitList"
        disabled
        @update-unit="handleUpdateUnit"
      />
    </template>
  </ComponentAttributeBaseRow>
</template>

<style lang="sass" scoped>
:deep(.v-field.v-field--appended)
  padding: 0 !important
</style>
