<script setup lang="ts">
import DropDown from '@/components/DropDown.vue'
import { ref, computed } from 'vue'
import { SelectItem } from '@/utils/types'
import { useI18n } from 'vue-i18n'

interface Props {
  analyticsFunctionsItems: SelectItem[]
}

const emit = defineEmits<{
  (e: 'update:analyticsFunction', value: string): void
}>()

const { t } = useI18n()

const props = defineProps<Props>()

const selectedAnalyticsFunction = ref<string>('all')

const menuInnerText = computed<string>(() => {
  const selectedItem = props.analyticsFunctionsItems.find(item => item.value === selectedAnalyticsFunction.value)

  if (selectedAnalyticsFunction.value === 'all') {
    return ''
  }

  return selectedItem?.title || ''
})

function handleSelectAnalyticsFunction (value: string) {
  selectedAnalyticsFunction.value = value
  emit('update:analyticsFunction', value)
}
</script>

<template>
  <DropDown
    id="analysis-dropdown"
    :items="analyticsFunctionsItems"
    :label="t('analysis')"
    :value="selectedAnalyticsFunction"
    color="neutral-darken2"
    menu-icon="fa:far fa-chevron-down"
    @update:value="handleSelectAnalyticsFunction"
  >
    <template #selection>
      <span
        data-testid="drop-down-selection"
        class="text-no-wrap text-cta text-neutral-darken4"
        v-text="menuInnerText"
      />
    </template>
    <template #item-prepend="{ item }">
      <v-icon
        v-if="item.value === selectedAnalyticsFunction"
        class="text-neutral-darken2"
        start
        size="small"
      >
        fa:far fa-check
      </v-icon>
      <span
        v-else
        class="tw-w-6"
      />
    </template>
    <template #item-title="{ item }">
      <span
        class="text-neutral-darken3 text-cta item-text"
        v-text="item.title"
      />
    </template>

    <template #item-append="{item}">
      <v-chip
        v-if="item.raw.custom"
        size="small"
        color="success"
        data-testid="analytics-function-active-chip"
        class="tw-mt-4 tw-capitalize tw-font-medium my-auto text-success-darken2 tw-border-[rgb(var(--v-theme-success-lighten3))] tw-border-solid tw-border"
      >
        {{ t('active') }}
      </v-chip>
    </template>
  </DropDown>
</template>

<style lang="sass" scoped>
:deep(.drop-down)
  &.v-input--is-focused
    .label
      color: rgb(var(--v-theme-neutral-darken2)) !important

    :deep(fieldset)
      border-width: 1px
      color: rgb(var(--v-theme-neutral)) !important

  &:hover
    .label
      color: rgb(var(--v-theme-neutral-darken2)) !important

    :deep(fieldset)
      color: rgb(var(--v-theme-neutral-lighten2)) !important

    &.v-input--is-focused
      :deep(fieldset)
        color: rgb(var(--v-theme-neutral)) !important

    .v-input__control
      background-color: rgb(var(--v-theme-neutral-lighten4)) !important

:deep(.drop-down-list-item)
  &:hover
    background-color: rgb(var(--v-theme-neutral-lighten4)) !important
    color: rgb(var(--v-theme-neutral-darken2)) !important

  .item-text
    color: rgb(var(--v-theme-neutral-darken2)) !important

  .subtitle
    color: rgb(var(--v-theme-neutral-darken2)) !important
  &:active
    background-color: transparent !important

:deep(.v-list-item--active)
  background: transparent !important

:deep(.v-list-item--active .v-list-item__overlay)
  background: transparent !important
</style>

<i18n locale="de" lang="json">
  {
    "analysis": "Analyse",
    "active": "Aktiv"
  }
</i18n>
<i18n locale="en" lang="json">
  {
    "analysis": "Analysis",
    "active": "Active"
  }
</i18n>
