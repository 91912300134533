<script setup lang="ts">
import DropDown, { type DropDownItem } from '@/components/DropDown.vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import { computed } from 'vue'
import { textForLocale } from '@/utils/helpers/locale'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

const enumItems = computed<DropDownItem[]>(() => {
  return (props.attribute.value_options ?? []).map(option => {
    return {
      value: option.key,
      title: textForLocale(option.nameDE, option.nameEN),
    }
  })
})

function handleUpdateAttributeOption (newValue: string) {
  emits('update:attribute', { ...props.attribute, new_value: newValue })
}
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="attribute"
  >
    <template #custom-input>
      <DropDown
        :items="enumItems"
        menu-icon="fa:far fa-chevron-down"
        :select-first-item="false"
        color="neutral-darken2"
        :value="attribute.value"
        @update:value="handleUpdateAttributeOption"
      />
    </template>
  </ComponentAttributeBaseRow>
</template>

<style lang="sass" scoped>
:deep(.drop-down)
  .text-cta
    font-weight: 400 !important
    color: rgb(var(--v-theme-neutral-darken3)) !important
    opacity: 1 !important
    white-space: normal !important
  .fa-chevron-down
    color: rgb(var(--v-theme-neutral-darken3)) !important
    opacity: 1
:deep(.v-list-item__content)
  .text-cta
    font-weight: 400 !important
    color: rgb(var(--v-theme-neutral-darken3)) !important
    opacity: 1 !important
</style>
