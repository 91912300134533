<script setup lang="ts">
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

function handleUpdateAttributeValue (newAttributeValue: string) {
  emits('update:attribute', {
    ...props.attribute,
    new_value: newAttributeValue.trim(),
  })
}

function getValidURL (url: string): string {
  if (!url) {
    return ''
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }

  return `https://${url}`
}
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="attribute"
    @update:attribute-value="handleUpdateAttributeValue"
    @keydown.enter.prevent="(event: KeyboardEvent) => handleUpdateAttributeValue((event.target as HTMLInputElement).value)"
  >
    <template
      #append-inner
    >
      <v-btn
        class="mx-2 text-neutral-darken2 tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border-solid tw-border"
        size="24"
        variant="outlined"
        target="_blank"
        data-testid="attribute-link-button"
        :href="getValidURL(props.attribute?.value)"
        :disabled="props.attribute?.value ? false : true"
        @click.stop
      >
        <v-icon
          size="14"
          color="neutral-darken3"
        >
          fa:far fa-arrow-up-right
        </v-icon>
      </v-btn>
    </template>
  </ComponentAttributeBaseRow>
</template>

<style scoped lang="sass">
  :deep(.v-field__append-inner)
    border-left: 1px solid rgb(var(--v-theme-neutral-lighten1))
    width: 40px

  :deep(.v-field.v-field--appended)
    padding: 0
</style>
